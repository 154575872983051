<template>
  <h1>パスワード設定</h1>
  現在のパスワード<br>
  <input type="password" v-model="oldPassword"><br>
  新しいパスワード<br>
  <input type="password" v-model="newPassword"><br>
  <input type="password" v-model="newPasswordConfirm"><br>
  <button type="submit" @click="changePassword">変更</button>
  <div>{{ message }}</div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: 'Setting',
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      message: ''
    }
  },
  methods: {
    changePassword() {
      // 入力した新パスワードが間違っていなければパスワード変更
      if (this.newPassword == this.newPasswordConfirm) {
        Auth.currentAuthenticatedUser()
          .then(user => {
            return Auth.changePassword(user, this.oldPassword, this.newPassword);
          })
          .then(data => {
            console.log(data)
            this.message = "パスワード変更しました"

            this.oldPassword = ""
            this.newPassword = ""
            this.newPasswordConfirm = ""
          })
          .catch(err => {
            console.log(err)
            this.message = "パスワード変更に失敗しました（" + err.message + "）"
          });
      } else {
        this.message = "新しいパスワードが一致しません"
      }
    }
  }
}
</script>

<style scoped>

</style>