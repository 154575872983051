<template>
  <h1>設定</h1>

  <ul>
    <li><router-link to="/setting/email">メールアドレス変更</router-link></li>
    <li><router-link to="/setting/password">パスワード</router-link></li>
  </ul>
</template>

<script>
export default {
  name: 'Setting'
}
</script>

<style scoped>
ul {
  list-style-type: none;
  padding-inline-start: 0;
}
</style>