<template>
  <h1>メールアドレス設定</h1>

  <div>
    新しいメールアドレス<br>
    <input type="email" v-model="email"><br>
    <button type="submit" @click="updateEmail">変更</button><br>
  </div>

  <div v-if="emailSended">
    確認コード
    <input type="number" v-model="code"><br>
    <button type="submit" @click="verifyEmail(code)">確認</button><br>
  </div>
  <div>{{ message }}</div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: 'Setting',
  data() {
    return {
      email: '',
      code: '',
      message: '',
      emailSended: false
    }
  },
  methods: {
    updateEmail: async function () {
      let user = await Auth.currentAuthenticatedUser();

      let result = await Auth.updateUserAttributes(user, {
        'email': this.email,
      });
      console.log(result); // SUCCESS
      if (result == "SUCCESS") {
        alert('確認メールを送信しました')
        this.emailSended = true
      }
    },
    verifyEmail: async function (code) {
      let result = await Auth.verifyCurrentUserAttributeSubmit('email', code).catch(err => {
          console.log(err)
          this.message = err.message
        });
      console.log(result);
      if (result == "SUCCESS") {
        alert('メールアドレスを変更しました')

        this.email = ""
        this.code = ""
        this.message = ""
        this.email = false
      }
    }
  }
}
</script>

<style scoped>

</style>